'use strict'

import { ApiLabellevie } from '../index'
import mbObject from '../../mixins/objects'

const _getHeadersWithByPassToken = () => {
  return Object.assign({}, ApiLabellevie.defaults.headers.common, { 'x-api-bypass-token': process.env.VUE_APP_DELEEV_X_API_BYPASS_TOKEN })
}

export default {
  getQisQds (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/labellevie/products-qiqd`, { params: params || {}, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getQiQd (productId, add) {
    return new Promise((resolve, reject) => {
      var _params = {}
      if (add) _params.add = 1
      ApiLabellevie
        .get(`/labellevie/products-qiqd/${productId}`, { params: _params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateQiQd (productId, data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .patch(`/labellevie/products-qiqd/${productId}`, data, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  bulkUpdateQiQd (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .put('/labellevie/bulk/products-qiqd', data, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  synchQiQd (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post('/labellevie/products-qiqd/synch', data, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  synchWithRulesQiQd (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post('/labellevie/products-qiqd/synch-with-rules', data, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSynchProductQiQdInfos () {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get('/labellevie/products-qiqd/synch-infos', { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  replaceQiQd (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post('/labellevie/products-qiqd/replace', data, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  exportProductQiQdAndSales (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get('/labellevie/products-qiqd/export', { params: params || {}, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getPriceComparison (productId) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/products/${productId}/price-comparison`, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSubstitute (productId) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/labellevie/products-substitute/${productId}`, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateSubstitute (productId, data) {
    return new Promise((resolve, reject) => {
      let _data = data.map(o => {
        return mbObject.pick(o, ['product_id', 'quantity', 'from'])
      })
      ApiLabellevie
        .put(`/labellevie/products-substitute/${productId}`, { data: _data }, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSubstituteToDo (params) {
    params = params || { limit: 100 }
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get('/labellevie/products-substitute/todo', { params: params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  excludeProductFromSubstituteToDo (productId) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .delete(`/labellevie/products-substitute/${productId}/todo`, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getQiQdUpdateRules (active, params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/products/qiqd-update-rules${active === true ? '/active' : ''}`, { params: params || {}, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  addQiQdUpdateRule (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post('/products/qiqd-update-rules/', data, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateQiQdUpdateRule (ruleId, data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .put(`/products/qiqd-update-rules/${ruleId}`, data, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  bulkUpdateQiQdRules (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post(`/products/qiqd-update-rules/bulk`, data, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSupplierProducts (supplier, params) {
    return new Promise((resolve, reject) => {
      var _params = Object.assign({}, params)
      if (supplier === 'superu') {
        let _search = []
        _search.push(_params.search || null)
        _search.push(_params.famille || null)
        _search.push(_params.type || null)
        _search.push(_params.disponibility || null)

        if (_search.filter(o => o !== null).length) _params.search = _search.join('||')
      }

      ApiLabellevie
        .get(`/labellevie/${supplier}/products`, { params: _params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSupplierProductInfos (supplier, params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/labellevie/${supplier}/products/${params.code}/infos`, { params: params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSupplierFamilles (supplier) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/labellevie/${supplier}/familles`, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  flagSupplierProductAsCreated (supplier, productCode) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .put(`/labellevie/${supplier}/products/${productCode}`, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductsMargins (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/deleev/products/margins`, { params: params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductMargins (productId, siteId) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/deleev/products/${productId}/margins`, { params: { site_id: siteId }, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  computeProductMargins (productId, siteId) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post(`/deleev/products/${productId}/margins`, { site_id: siteId }, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateProductMargins (productId, data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .put(`/deleev/products/${productId}/margins`, data || {}, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  bulkUpdateGrossSellingPrice (siteId, supplierId, data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post(`/deleev/products/update-price/${siteId}/${supplierId}`, data || {}, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getBulkUpdateGrossSellingPriceInfos (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get('/deleev/products/update-price/update-infos', { params: params || {}, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductsStats (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/deleev/products/days-stats`, { params: params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  setProductsDaysStatsEstimation (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post(`/deleev/products/days-stats/estimation`, data, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSystemUProduct (code) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/deleev/systemeuoffer/products/${code}`, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSystemUFamilles () {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/deleev/systemeuoffer/familles`, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSystemUCatalogue () {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/deleev/systemeuoffer/catalogue`, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSystemUProducts (params) {
    params = params || {}
    let _params = {
      offset: params.offset || 0,
      limit: params.limit || 100
    }
    if (params.with_created) {
      _params.with_created = true
    }
    if (params.famille || params.catalogue || params.type || params.typeul || params.search || params.tosource) {
      _params.filters = {}
      if (params.famille) _params.filters.famille = params.famille
      if (params.catalogue) _params.filters.catalogue = params.catalogue
      if (params.type === 'frozen') _params.filters.is_frozen = true
      if (params.type === 'fresh') _params.filters.is_fresh = true
      if (params.type === 'primeur') _params.filters.is_primeur = true
      if (params.type === 'secs') _params.filters.is_secs = true
      if (params.typeul) _params.filters.type_ul = params.typeul
      if (params.search) _params.filters.search = params.search
      if (params.tosource) _params.filters.tosource = params.tosource
    }
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/deleev/systemeuoffer/products`, { params: _params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  toggleSystemUProductToSource (code) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post(`/deleev/systemeuoffer/products/${code}/toggle-tosource`, {}, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductsCatalog (params) {
    params = params || {}
    params.supplier_id = params.supplier_id || 404
    let _params = {
      offset: params.offset || 0,
      limit: params.limit || 100
    }
    if (params.with_created) {
      _params.with_created = true
    }
    if (params.search) {
      _params.filters = { search: params.search }
    }
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/products/catalog/${params.supplier_id}`, { params: _params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductsLogsPda (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/products/logs-pda`, { params: params || {}, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductsPriceComparison (supplier, params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/labellevie/${supplier}/products-price-comparison`, { params: params || {}, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateProductComparison (supplier, productId, data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .put(`/labellevie/${supplier}/products/${productId}/update`, data || {}, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  deleteProductComparison (supplier, productId) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .delete(`/labellevie/${supplier}/products/${productId}`, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getOrdersCrates (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/products/orders-crates`, { params: params || {}, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductsDLCTourLogs (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/deleev/logs/dlctour`, { params: params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductsMoneyLogs (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/deleev/products/logs-money`, { params: params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  exportProductsCreditsLogs (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/deleev/products/credits-logs/export`, { params: params, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getReapproProducts () {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/hubs/products`, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateReapproProduct (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post(`/hubs/products`, data, { headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getReapproOrders (centerId, params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/hubs/${centerId}/orders`, { params: params || {}, headers: _getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  }
}
